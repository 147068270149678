<template>
  <div>
    <vs-list>
      <vs-list-header
        title="Animation swipe"
        color="primary"
      />
      <draggable
        :list="list"
        class="cursor-move"
      >
        <transition-group>
          <vs-list-item
            v-for="listitem in list"
            :key="listitem.email"
            class="animation"
            :title="listitem.name"
            :subtitle="listitem.email"
          >
            <vs-avatar
              slot="avatar"
              :text="listitem.name"
            />
          </vs-list-item>
        </transition-group>
      </draggable>
    </vs-list>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name: 'DragnDropAnimation',
  components: {
    draggable
  },
  data: () => ({
    title: 'DragnDropAnimation',
    list: [
      {
        name: 'Quantum Computer Services',
        email: 'AOL'
      },
      {
        name: 'BackRub Sfgsg',
        email: 'Google'
      },
      {
        name: 'SoundMusic adfg',
        email: 'Best Buy'
      },
      {
        name: 'Apple Computers',
        email: 'Apple, Inc.'
      }
    ]
  })
};
</script>
<style>
.animation {
  transition: all 0.5s;
}
</style>